import { compose, anyPass, ifElse, always, add, allPass, pathEq } from 'ramda';

import {
  ArtworkFragmentExtended,
  AuctionFragment,
} from 'gql/hasura/hasura-fragments.generated';
import { isUnixDateInPast } from 'utils/dates/dates';
import { isNumber, isString, notEmptyOrNil } from 'utils/helpers';
import { areKeysEqual } from 'utils/users';

const isStringOrNumber = anyPass([isString, isNumber]);

// buffer in seconds
const PAST_CONFIRMATIONS_BUFFER = 30;

const isAuctionInPastWithBuffer = compose(
  isUnixDateInPast,
  add(PAST_CONFIRMATIONS_BUFFER),
  Number
);

export const isAuctionEnded = ifElse(
  // if the value is present
  isStringOrNumber,
  // run the value through the check
  isAuctionInPastWithBuffer,
  // otherwise return false
  always(false)
);

export const isArtworkAuctionWinner = (
  publicKey: string,
  auction: AuctionFragment
) => {
  const highestBidder = auction?.highestBidder;
  const isOwned = areKeysEqual([publicKey, highestBidder]);
  return isOwned;
};

export const isAuctionLive = allPass([
  notEmptyOrNil,
  (date: string) => !isAuctionEnded(date),
]);

type ArtworkWithAuctions = {
  auctions?: AuctionFragment[];
};

export const getMostRecentAuction = (
  artwork: ArtworkWithAuctions | undefined
) => {
  if (!artwork) return;
  return artwork.auctions ? artwork.auctions[0] : undefined;
};

export const isAuctionOpen: (
  artwork: Pick<ArtworkFragmentExtended, 'auctions'>
) => boolean = allPass([
  notEmptyOrNil,
  pathEq(['auctions', 0, 'status'], 'OPEN'),
]);
