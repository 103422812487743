import { styled } from '@f8n-frontend/stitches';

import Grid from 'components/base/Grid';

const ArtworkCardHeader = styled(Grid, {
  padding: '$5',
  flex: 1,
  position: 'relative',
  zIndex: 3,
});

export default ArtworkCardHeader;
