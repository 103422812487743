import Pulse from 'components/Pulse';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Grid from 'components/base/Grid';
import Heading from 'components/base/Heading';
import Text from 'components/base/Text';
import ArtworkCardMetaBlock from 'components/cards/artwork/subcomponents/meta/ArtworkCardMetaBlock';
import ArtworkCardMetaContainer from 'components/cards/artwork/subcomponents/meta/ArtworkCardMetaContainer';
import ArtworkCardMetaLabel, {
  ArtworkCardMetaValue,
} from 'components/cards/artwork/subcomponents/meta/ArtworkCardMetaLabel';
import AuctionCountdown from 'components/market-widget/AuctionCountdown';
import { OfferProgressCircle } from 'components/transactions/offer/OfferProgressCircle';

import { formatETHWithSuffix } from 'utils/formatters';
import { notEmptyOrNil } from 'utils/helpers';

import { UserLight } from 'types/Account';
import { ActiveOffer } from 'types/Offer';

import ArtworkCardOwner from '../ArtworkCardOwner';

interface ArtworkMetaGenericProps {
  value?: string;
  label?: string;
  isCreatorOwner: boolean;
  owner: UserLight;
  isSecondary?: boolean;
  activeOffer?: ActiveOffer;
}

export default function ArtworkMetaGeneric(props: ArtworkMetaGenericProps) {
  const {
    value,
    label,
    owner,
    isCreatorOwner,
    isSecondary = false,
    activeOffer,
  } = props;

  const hasValue = notEmptyOrNil(value);
  const hasLabel = notEmptyOrNil(label);

  const hasValueOrLabel = hasValue || hasLabel;

  return (
    <ArtworkCardMetaContainer>
      {hasValueOrLabel && (
        <ArtworkCardMetaBlock>
          {label && (
            <ArtworkCardMetaLabel color="light">{label}</ArtworkCardMetaLabel>
          )}
          {value && (
            <ArtworkCardMetaValue color={isSecondary ? 'light' : 'black'}>
              {value}
            </ArtworkCardMetaValue>
          )}
        </ArtworkCardMetaBlock>
      )}

      {/* TODO: tidy up this ternary + extract into component */}
      {activeOffer ? (
        <Grid
          css={{
            gap: '$1',
            justifyContent: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <Flex css={{ alignItems: 'center', justifyContent: 'flex-end' }}>
            {activeOffer.expiresAt && (
              <OfferProgressCircle
                size={14}
                strokeWidth={2}
                expiresAt={activeOffer.expiresAt}
                css={{ marginRight: '$1' }}
              />
            )}

            <Text size={1} weight="semibold" color="dim">
              {formatETHWithSuffix(activeOffer.amountInETH)}
            </Text>
          </Flex>

          {!isCreatorOwner && owner && <ArtworkCardOwner owner={owner} />}
        </Grid>
      ) : (
        !isCreatorOwner &&
        owner && (
          <Flex
            css={{
              width: '100%',
              minWidth: 0,
              justifyContent: 'space-between',
            }}
          >
            <Box />
            <ArtworkCardOwner owner={owner} />
          </Flex>
        )
      )}
    </ArtworkCardMetaContainer>
  );
}

interface ArtworkMetaLiveAuctionProps {
  value: string;
  label: string;
  endsAt: string;
}

export function ArtworkMetaLiveAuction(props: ArtworkMetaLiveAuctionProps) {
  const { value, label, endsAt } = props;

  return (
    <ArtworkCardMetaContainer>
      <ArtworkCardMetaBlock
        css={{
          gap: '$3',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box css={{ position: 'relative', minWidth: 0 }}>
          <ArtworkCardMetaLabel color="dark" css={{ marginBottom: '$1' }}>
            {label}
          </ArtworkCardMetaLabel>
          <Flex>
            <Box
              css={{
                marginRight: '$1',
                marginLeft: -6,
              }}
            >
              <Pulse color="$white100" size={22} />
            </Box>
            <ArtworkCardMetaValue>{value}</ArtworkCardMetaValue>
          </Flex>
        </Box>
        <AuctionCountdown endsAt={endsAt} variant="legacy-artwork-card" />
      </ArtworkCardMetaBlock>
    </ArtworkCardMetaContainer>
  );
}

type ArtworkCardMetaEndedAuctionProps = {
  value: string;
  label: string;
};

export function ArtworkCardMetaEndedAuction(
  props: ArtworkCardMetaEndedAuctionProps
) {
  const { value, label } = props;

  return (
    <ArtworkCardMetaContainer>
      <ArtworkCardMetaBlock css={{ display: 'flex' }}>
        <Box>
          <ArtworkCardMetaLabel color="dark" css={{ marginBottom: '$1' }}>
            {label}
          </ArtworkCardMetaLabel>
          <ArtworkCardMetaValue>{value}</ArtworkCardMetaValue>
        </Box>
      </ArtworkCardMetaBlock>
      <Heading
        css={{
          color: '$white60',
          '@bp0-max': {
            marginBottom: '$1',
          },
        }}
      >
        Ended
      </Heading>
    </ArtworkCardMetaContainer>
  );
}
