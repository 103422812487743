import { flip, indexOf, sortWith, propOr } from 'ramda';

import { ArtworkFragmentExtended } from 'gql/hasura/hasura-fragments.generated';
import { isAuctionLive } from 'utils/auctions/auctions';
import { parseDateToUnix } from 'utils/dates/dates';
import {
  getFirstValue,
  isValueInArray,
  maybeToString,
  rejectNils,
} from 'utils/helpers';

import { UserLight } from 'types/Account';

type Artwork = ArtworkFragmentExtended;

type HasuraBuyNowMarket = Artwork['buyNows'][number];
type HasuraPrivateSaleMarket = Artwork['mostRecentPrivateSales'][number];
type HasuraOfferMarket = Artwork['offers'][number];
type HasuraAuctionMarket = Artwork['auctions'][number];

type BaseMarket = {
  id: string;
  amountInEth: number;
  eventDate: string | null;
  marketActors: string[];
};

type BuyNowMarket = BaseMarket & {
  marketType: 'BUY_NOW';
  buyer: string | null;
  seller: string;
};

export type OfferMarket = BaseMarket & {
  marketType: 'OFFER';
  buyer: string;
  buyerUser: UserLight;
  seller: string | null;
};

type BaseAuctionMarket = BaseMarket & {
  auctionId: number;
  seller: string;
  duration: number | null;
};

export type AuctionMarket =
  | (BaseAuctionMarket & {
      marketType: 'ENDED_AUCTION';
      buyer: string;
      buyerUser: UserLight;
    })
  | (BaseAuctionMarket & {
      marketType: 'FINALIZED_AUCTION';
      buyer: string;
      buyerUser: UserLight;
    })
  | (BaseAuctionMarket & {
      marketType: 'LISTED_AUCTION';
      buyer: null;
      buyerUser: null;
    })
  | (BaseAuctionMarket & {
      marketType: 'LIVE_AUCTION';
      buyer: string;
      buyerUser: UserLight;
    });

type PrivateSaleMarket = BaseMarket & {
  marketType: 'PRIVATE_SALE';
  buyer: string | null;
  seller: string;
};

export type Market =
  | AuctionMarket
  | BuyNowMarket
  | OfferMarket
  | PrivateSaleMarket;

export type AuctionMarketType = AuctionMarket['marketType'];
export type MarketType = Market['marketType'];

const MARKET_PRIORITY: MarketType[] = [
  'LIVE_AUCTION',
  'ENDED_AUCTION',
  'BUY_NOW',
  'LISTED_AUCTION',
  'FINALIZED_AUCTION',
  'OFFER',
  'PRIVATE_SALE',
];

export type ArtworkMarkets = Pick<
  Artwork,
  'mostRecentPrivateSales' | 'buyNows' | 'offers' | 'auctions' | 'collection'
>;

const sortByMarketPriority = (a: Market, b: Market) =>
  indexOf(a.marketType, MARKET_PRIORITY) -
  indexOf(b.marketType, MARKET_PRIORITY);

const sortByMarketDate = (a: Market, b: Market) => {
  return a.eventDate === null
    ? 0
    : parseDateToUnix(b.eventDate) - parseDateToUnix(a.eventDate);
};

export function getPriorityMarketFromArtwork(artwork: ArtworkMarkets) {
  const markets = normalizeArtworkMarkets(artwork);
  const sortedMarkets = sortWith(
    [sortByMarketPriority, flip(sortByMarketDate)],
    markets
  );
  return getFirstValue(sortedMarkets);
}

export function findMarketByType(
  artwork: ArtworkMarkets,
  type: AuctionMarket['marketType']
): AuctionMarket | undefined;

export function findMarketByType(
  artwork: ArtworkMarkets,
  type: BuyNowMarket['marketType']
): BuyNowMarket | undefined;

export function findMarketByType(
  artwork: ArtworkMarkets,
  type: OfferMarket['marketType']
): OfferMarket | undefined;

export function findMarketByType(
  artwork: ArtworkMarkets,
  marketType: MarketType
) {
  const markets = normalizeArtworkMarkets(artwork);
  return markets.find((market) => market.marketType === marketType);
}

export function getLastSoldForMarket(artwork: ArtworkMarkets) {
  const privateSales = getValueOrEmptyList(
    artwork,
    'mostRecentPrivateSales'
  ).map(createPrivateSaleMarket);

  // get accepted buy nows
  const buyNows = getValueOrEmptyList(artwork, 'buyNows')
    .filter((buyNow) => isValueInArray(buyNow.status, ['ACCEPTED']))
    .map(createBuyNowMarket);

  // get accepted offers
  const offers = getValueOrEmptyList(artwork, 'offers')
    .filter((offer) => isValueInArray(offer.status, ['ACCEPTED']))
    .map(createOfferMarket);

  // get ended and finalized auctions
  const auctions = getValueOrEmptyList(artwork, 'auctions')
    .filter((auction) => isValueInArray(auction.status, ['ENDED', 'FINALIZED']))
    .map(createAuctionMarket);

  const mergedMarkets = [...privateSales, ...buyNows, ...offers, ...auctions];

  const sortedMarkets = mergedMarkets.sort(sortByMarketDate);

  return getFirstValue(sortedMarkets);
}

function normalizeArtworkMarkets(artwork: ArtworkMarkets) {
  const privateSales = getValueOrEmptyList(
    artwork,
    'mostRecentPrivateSales'
  ).map(createPrivateSaleMarket);

  // get open buy nows
  const buyNows = getValueOrEmptyList(artwork, 'buyNows')
    .filter((buyNow) => isValueInArray(buyNow.status, ['OPEN']))
    .map(createBuyNowMarket);

  // get highest offers
  const offers = getValueOrEmptyList(artwork, 'offers')
    .filter((offer) => isValueInArray(offer.status, ['HIGHEST']))
    .map(createOfferMarket);

  // get open and ended auctions
  const auctions = getValueOrEmptyList(artwork, 'auctions')
    .filter((auction) => isValueInArray(auction.status, ['OPEN', 'ENDED']))
    .map(createAuctionMarket);

  const mergedMarkets = [...privateSales, ...buyNows, ...offers, ...auctions];

  return mergedMarkets.sort(sortByMarketDate);
}

function createBuyNowMarket(market: HasuraBuyNowMarket): BuyNowMarket {
  return {
    id: market.id,
    amountInEth: market.amountInETH,
    marketType: 'BUY_NOW',
    marketActors: rejectNils([market.seller, market.buyer]),
    eventDate: market.acceptedAt,
    seller: market.seller,
    buyer: market.buyer,
  };
}

function createPrivateSaleMarket(
  market: HasuraPrivateSaleMarket
): PrivateSaleMarket {
  return {
    id: market.id,
    amountInEth: market.saleAmountInETH,
    marketType: 'PRIVATE_SALE',
    marketActors: rejectNils([market.seller, market.buyer]),
    eventDate: market.soldAt,
    seller: market.seller,
    buyer: market.buyer,
  };
}

function createOfferMarket(market: HasuraOfferMarket): OfferMarket {
  return {
    id: market.id,
    amountInEth: market.amountInETH,
    marketType: 'OFFER',
    marketActors: rejectNils([market.seller, market.buyer]),
    eventDate: market.expiresAt,
    seller: market.seller,
    buyer: market.buyer,
    buyerUser: market.userBuyer,
  };
}

function createAuctionMarket(market: HasuraAuctionMarket): AuctionMarket {
  const unixEndDate = market.endsAt
    ? parseDateToUnix(market.endsAt)
    : undefined;

  const isLive = isAuctionLive(unixEndDate);
  const isEnded = market.status === 'ENDED';
  const isFinalized = market.status === 'FINALIZED';

  const marketType = isEnded
    ? 'ENDED_AUCTION'
    : isFinalized
      ? 'FINALIZED_AUCTION'
      : isLive
        ? 'LIVE_AUCTION'
        : 'LISTED_AUCTION';

  const { id, seller } = market;

  const marketActors = rejectNils([market.seller, market.highestBidder]);
  const eventDate = market.endsAt;
  const duration = market.duration;

  const auctionBase = {
    id,
    marketActors,
    eventDate,
    seller,
    duration,
  };

  // This is a "reserve price". The seller is open to an auction
  // on their NFT, but no auction has started yet.
  if (marketType === 'LISTED_AUCTION') {
    return {
      ...auctionBase,
      marketType,
      id,
      amountInEth: market.reservePriceInETH,
      buyer: null,
      buyerUser: null,
      auctionId: market.auctionId,
    };
  }

  return {
    ...auctionBase,
    marketType,
    amountInEth: market.highestBidAmount as number,
    buyer: market.highestBidder as string,
    buyerUser: market.highestBidderUser as UserLight,
    auctionId: market.auctionId,
  };
}

function getValueOrEmptyList<T extends ArtworkMarkets, U extends keyof T>(
  artwork: T,
  key: U
) {
  return propOr<[], T, T[U]>([], maybeToString(key), artwork);
}

export const getMarketData = (artwork: ArtworkFragmentExtended) => ({
  priorityMarket: getPriorityMarketFromArtwork(artwork),
  lastSoldForMarket: getLastSoldForMarket(artwork),
});
