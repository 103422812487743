import { ComponentProps } from '@stitches/react';

import Flex from 'components/base/Flex';
import { PopoverMenuOption } from 'components/popover/types';

import { isUnsupportedArtworkAsset } from 'utils/artwork/artwork';
import {
  buildArtworkAssetUrl,
  buildPosterUrl,
  FALLBACK_IMAGE_IMGIX_BLUR,
} from 'utils/assets';
import { isNumber } from 'utils/helpers';
import {
  getPriorityMarketFromArtwork,
  findMarketByType,
} from 'utils/markets/markets';
import { isFlaggedForModeration } from 'utils/moderation';
import { getPath } from 'utils/router';

import { UserLight } from 'types/Account';
import { ArtworkExtended } from 'types/Artwork';
import { AssetBuilder, AssetMimeType, ImgixOptions } from 'types/Assets';
import { NftFilter } from 'types/Nft';
import { FallbackAssetFragment } from 'types/artwork/artwork';

import ModeratedBanner from '../shared/ModeratedBanner';

import ArtworkCardSkeleton from './ArtworkCardSkeleton';
import ArtworkCardCreator from './subcomponents/ArtworkCardCreator';
import ArtworkCardHeader from './subcomponents/ArtworkCardHeader';
import ArtworkCardMarket from './subcomponents/ArtworkCardMarket';
import ArtworkCardMedia from './subcomponents/ArtworkCardMedia';
import ArtworkCardMenu from './subcomponents/ArtworkCardMenu';
import ArtworkCardRoot from './subcomponents/ArtworkCardRoot';
import ArtworkHiddenLink from './subcomponents/ArtworkHiddenLink';
import { ArtworkCardProps as BaseArtworkCardProps } from './types';

type ArtworkCardType = 'regular' | 'detailed';

type ArtworkCardContainerProps = ComponentProps<typeof ArtworkCardRoot>;

export interface ArtworkCardProps extends BaseArtworkCardProps {
  artwork: ArtworkExtended;
  creator: UserLight;
  cardType?: ArtworkCardType;
  fallbackAsset: FallbackAssetFragment;
  assetBuilder?: AssetBuilder;
  mediaOverlay?: ArtworkCardContainerProps['mediaOverlay'];
  menuOptions?: PopoverMenuOption[];
  isOversized?: boolean;
  forceRenderMedia?: boolean;
  isDragDropEnabled?: boolean;
}

export default function ArtworkCard(props: ArtworkCardProps) {
  const {
    artwork,
    creator,
    cardType = 'detailed',
    fallbackAsset,
    assetBuilder = 'imgix',
    mediaOverlay,
    menuOptions = [],
    itemCount = 1,
    isOversized = false,
    forceRenderMedia = false,
    variant = 'nft',
    isDragDropEnabled = false,
  } = props;

  if (!artwork) {
    return <ArtworkCardSkeleton />;
  }

  const defaultImgixOptions: ImgixOptions = {
    h: isOversized ? 1320 : 640,
    q: 80,
    isOversized,
    quality: 'preview',
    auto: 'format,compress',
    blur: isUnsupportedArtworkAsset(artwork)
      ? FALLBACK_IMAGE_IMGIX_BLUR
      : undefined,
  };

  const optimizedAssetUrl =
    assetBuilder === 'imgix'
      ? buildArtworkAssetUrl(defaultImgixOptions, artwork)
      : artwork.assetPath;

  // TODO: when this card uses the new API, update this line to match variant condition like Aglolia Artwork card
  const artworkPath = getPath.token.page({
    chainId: artwork.chainId,
    contractAddress: artwork.contractAddress,
    tokenId: artwork.tokenId,
  } as NftFilter);
  const posterUrl = buildPosterUrl(artwork);
  // @ts-expect-error null-checks
  const hasSplits = artwork?.splitRecipients?.aggregate?.count > 0;
  const priorityMarket = getPriorityMarketFromArtwork(artwork);
  const marketType = priorityMarket?.marketType;
  const isAuctionMode =
    marketType === 'LIVE_AUCTION' || marketType === 'ENDED_AUCTION';

  const moderationStatus = artwork?.moderationStatus;
  const isModerated = isFlaggedForModeration(moderationStatus);

  const activeOffer = findMarketByType(artwork, 'OFFER');

  const showMenu = menuOptions && menuOptions.some((option) => option.enabled);

  return (
    <ArtworkCardRoot
      isAuctionMode={isAuctionMode}
      itemCount={1}
      mediaOverlay={mediaOverlay}
    >
      {isModerated && <ModeratedBanner status={moderationStatus} />}
      <ArtworkCardMedia
        href={artworkPath}
        assetStatus={artwork.assetStatus}
        // @ts-expect-error null-checks
        optimizedAssetUrl={optimizedAssetUrl}
        fallbackAsset={fallbackAsset}
        posterUrl={posterUrl}
        // @ts-expect-error null-checks
        name={artwork.name}
        // @ts-expect-error null-checks
        collection={artwork.collection}
        creator={creator}
        // @ts-expect-error null-checks
        tokenId={artwork.tokenId}
        isOversized={isOversized}
        forceRenderMedia={forceRenderMedia}
        variant={variant}
        itemCount={itemCount}
        isDragDropEnabled={isDragDropEnabled}
        mimeType={artwork.mimeType as AssetMimeType}
      />
      <ArtworkCardHeader css={{ flexGrow: isOversized ? 0 : 1 }}>
        <Flex
          css={{
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <ArtworkCardCreator
            artwork={artwork}
            hasSplits={hasSplits}
            color={isAuctionMode ? 'dark' : 'light'}
          />

          {showMenu && (
            <ArtworkCardMenu
              isAuctionMode={isAuctionMode}
              options={menuOptions}
            />
          )}
        </Flex>
        {cardType === 'detailed' && (
          <ArtworkCardMarket
            artwork={artwork}
            // @ts-expect-error null-checks
            priorityMarket={priorityMarket}
            // @ts-expect-error null-checks
            marketType={marketType}
            // @ts-expect-error null-checks
            activeOffer={
              activeOffer
                ? {
                    amountInETH: activeOffer.amountInEth,
                    expiresAt: activeOffer.eventDate,
                  }
                : null
            }
          />
        )}
        {isNumber(artwork.tokenId) && (
          // @ts-expect-error null-checks
          <ArtworkHiddenLink name={artwork.name} href={artworkPath} />
        )}
      </ArtworkCardHeader>
    </ArtworkCardRoot>
  );
}
