import { styled } from '@f8n-frontend/stitches';

import Flex from 'components/base/Flex';

const ArtworkCardMetaContainer = styled(Flex, {
  paddingTop: '$4',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  minWidth: 0,
});

export default ArtworkCardMetaContainer;
