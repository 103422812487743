import { darkMode, styled } from '@f8n-frontend/stitches';

import InlineCountdown from 'components/InlineCountdown';
import RenderCountdown from 'components/RenderCountdown';
import Box from 'components/base/Box';
import Heading from 'components/base/Heading';
import ArtworkCardMetaLabel, {
  ArtworkCardMetaValue,
} from 'components/cards/artwork/subcomponents/meta/ArtworkCardMetaLabel';

import useCountdown from 'hooks/use-countdown';
import { useIsHydrated } from 'hooks/use-is-hydrated';
import { parseDateToUnix } from 'utils/dates/dates';

interface AuctionCountdownProps {
  endsAt: string;
  variant: 'legacy-artwork-card' | 'market-widget';
}

const AuctionEndsInLabel = styled(Heading, {
  [darkMode]: {
    color: '$black100',
  },
});

AuctionEndsInLabel.defaultProps = {
  color: 'dim',
  weight: 'medium',
};

/**
 * @deprecated, use RenderCountdown instead
 */
export default function AuctionCountdown(props: AuctionCountdownProps) {
  const { endsAt, variant } = props;

  const timestamp = parseDateToUnix(endsAt);
  const isHydrated = useIsHydrated();
  const { hasEnded } = useCountdown(timestamp);

  if (hasEnded || !isHydrated) {
    // TODO: add a loading state to make this smoother
    return null;
  }

  // TODO: split this out into an ArtworkCardAuctionCountdown component
  if (variant === 'legacy-artwork-card') {
    return (
      <Box>
        <ArtworkCardMetaLabel color="dark" css={{ marginBottom: '$1' }}>
          Ends in
        </ArtworkCardMetaLabel>
        <ArtworkCardMetaValue>
          <RenderCountdown
            endsAtTimestamp={timestamp}
            render={(countdown) => {
              return countdown.message ? countdown.message : null;
            }}
          />
        </ArtworkCardMetaValue>
      </Box>
    );
  }

  const getMessage = () => {
    return (
      <MarketWidgetAuctionHeading>
        <InlineCountdown timestamp={timestamp} variant="padded" />
      </MarketWidgetAuctionHeading>
    );
  };

  return (
    <Box>
      <AuctionEndsInLabel
        css={{
          '@bp0-max': {
            marginBottom: '$1',
          },
        }}
      >
        Auction ends in
      </AuctionEndsInLabel>
      {getMessage()}
    </Box>
  );
}

const MarketWidgetAuctionHeading = styled(Heading);
MarketWidgetAuctionHeading.defaultProps = {
  size: { '@bp0-max': 3, '@initial': 4, '@bp0': 5 },
  css: {
    whiteSpace: 'nowrap',
  },
};
