import Box from 'components/base/Box';
import UserTag from 'components/users/UserTag';

import SplitsIcon from 'assets/icons/split-icon.svg';

import { UserLight } from 'types/Account';
import { AlgoliaArtwork } from 'types/Algolia';
import { ArtworkExtended } from 'types/Artwork';

interface ArtworkCardCreatorProps {
  artwork: ArtworkExtended | AlgoliaArtwork;
  hasSplits?: boolean;
  color: 'dark' | 'light';
}

export default function ArtworkCardCreator(props: ArtworkCardCreatorProps) {
  const { artwork, hasSplits = false, color } = props;

  const creator = artwork?.creator;

  return (
    <Box
      css={{
        width: 'fit-content',
        alignSelf: 'flex-start',
        position: 'relative',
        zIndex: 2,
      }}
    >
      <UserTag
        isDark={color === 'dark'}
        nameVariant="prefer-username"
        size={1}
        type="avatar-text"
        user={creator as UserLight}
      />
      {hasSplits && (
        <SplitsIcon
          style={{
            background: '#000',
            color: '#fff',
            padding: 4,
            borderRadius: '100%',
            height: 16,
            position: 'absolute',
            left: -4,
            bottom: 0,
          }}
        />
      )}
    </Box>
  );
}
