import Box from 'components/base/Box';
import UserTag from 'components/users/UserTag';

import { UserLight } from 'types/Account';

interface ArtworkCardOwnerProps {
  owner: UserLight;
}

export default function ArtworkCardOwner(props: ArtworkCardOwnerProps) {
  const { owner } = props;

  return (
    <Box
      css={{
        position: 'relative',
        zIndex: 2,
        paddingY: 1,
        minWidth: 0,
        marginLeft: '$4',
      }}
    >
      <UserTag
        nameVariant="prefer-username"
        size={1}
        type="avatar-text"
        user={owner}
      />
    </Box>
  );
}
