import Theme from 'components/Theme';
import Box from 'components/base/Box';
import DropdownMenu from 'components/base/DropdownMenu';
import MeatballDropdown from 'components/dropdown/MeatballDropdown';
import { PopoverMenuOption } from 'components/popover/types';

type ArtworkCardMenuProps = {
  isAuctionMode: boolean;
  // TODO: update prop to use new shape for DropdownMenu
  options: PopoverMenuOption[];
};

export default function ArtworkCardMenu(props: ArtworkCardMenuProps) {
  const { isAuctionMode, options = [] } = props;

  return (
    <Box css={{ position: 'relative', zIndex: 4 }}>
      {/*TODO: move this Theme up higher in the ArtworkCard component, and remove the `isAuctionModeProp from this component */}
      <Theme theme={isAuctionMode ? 'dark' : 'light'}>
        <MeatballDropdown
          buttonCss={{
            marginY: -7,
            marginX: -4,
          }}
          align="end"
          side="top"
        >
          {options.map((option, index) => {
            if (option.enabled) {
              return (
                <DropdownMenu.Item
                  key={index}
                  onClick={() => option.onClick?.()}
                >
                  {option.icon}
                  {option.children}
                </DropdownMenu.Item>
              );
            }
          })}
        </MeatballDropdown>
      </Theme>
    </Box>
  );
}
