import AspectRatio from 'components/base/AspectRatio';
import Box from 'components/base/Box';
import Card from 'components/base/Card';
import Flex from 'components/base/Flex';
import Skeleton from 'components/base/Skeleton';

import { NFT_CARD_MIN_WIDTH } from 'design/tokens/size';

import ArtworkCardHeader from './subcomponents/ArtworkCardHeader';

export type ArtworkCardSkeletonVariant = 'auction' | 'price' | 'offer';

interface AvatarTextSkeletonProps {
  variant: 'publicKey' | 'username';
}

function AvatarTextSkeleton(props: AvatarTextSkeletonProps) {
  const { variant } = props;
  return (
    <Flex css={{ gap: '$2', alignItems: 'center' }}>
      <Skeleton.Avatar size={1} />
      {variant === 'publicKey' ? (
        <Flex css={{ alignItems: 'flex-end', gap: 2 }}>
          <Skeleton.Block css={{ width: 50, height: 14 }} />
          <Skeleton.Block css={{ width: 3, height: 3 }} />
          <Skeleton.Block css={{ width: 3, height: 3 }} />
          <Skeleton.Block css={{ width: 3, height: 3 }} />

          <Skeleton.Block css={{ width: 30, height: 14 }} />
        </Flex>
      ) : (
        <Skeleton.Block css={{ width: 100, height: 14 }} />
      )}
    </Flex>
  );
}

function AuctionValueSkeleton() {
  return (
    <>
      <Skeleton.Block css={{ width: 70, height: 14, marginBottom: '$2' }} />
      <Flex css={{ gap: '$2', alignItems: 'center' }}>
        <Skeleton.Circle css={{ width: 12, height: 12 }} />
        <Skeleton.Block css={{ width: 60, height: 18 }} />
      </Flex>
    </>
  );
}

function KeyValueSkeleton() {
  return (
    <Box css={{ minWidth: 120 }}>
      <Skeleton.Block css={{ width: 50, height: 14, marginBottom: '$2' }} />
      <Flex css={{ gap: '$2' }}>
        <Skeleton.Block css={{ width: 70, height: 18 }} />
      </Flex>
    </Box>
  );
}

interface ArtworkCardSkeletonProps {
  variant?: ArtworkCardSkeletonVariant;
}

export default function ArtworkCardSkeleton(props: ArtworkCardSkeletonProps) {
  const { variant } = props;

  const height: number | undefined = undefined;

  let bottomLeftContent: React.ReactChild | null = <KeyValueSkeleton />;
  let bottomRightContent: React.ReactChild | null = (
    <AvatarTextSkeleton variant="username" />
  );

  if (variant) {
    switch (variant) {
      case 'auction': {
        bottomLeftContent = <AuctionValueSkeleton />;
        bottomRightContent = <KeyValueSkeleton />;
        break;
      }
      case 'price': {
        bottomLeftContent = <KeyValueSkeleton />;
        bottomRightContent = null;
        break;
      }
      case 'offer': {
        bottomLeftContent = <KeyValueSkeleton />;
        bottomRightContent = <Skeleton.Block css={{ width: 60, height: 14 }} />;
        break;
      }
    }
  }

  //

  return (
    <Card
      css={{
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        pointerEvents: 'none',
        '@bp0': {
          minWidth: NFT_CARD_MIN_WIDTH,
        },
      }}
    >
      <AspectRatio
        ratio={1}
        css={{
          borderTopLeftRadius: '$2',
          borderTopRightRadius: '$2',
          backgroundColor: '$black5',
        }}
      />
      <Box
        css={{
          background: '$white100',
          borderBottomLeftRadius: '$2',
          borderBottomRightRadius: '$2',
        }}
      >
        <ArtworkCardHeader css={{ height }}>
          <Flex css={{ paddingBottom: '$4' }}>
            <AvatarTextSkeleton variant="username" />
          </Flex>
          <Flex
            css={{
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>{bottomLeftContent}</Box>
            <Box
              css={{ alignSelf: variant === 'offer' ? 'flex-end' : undefined }}
            >
              {bottomRightContent}
            </Box>
          </Flex>
        </ArtworkCardHeader>
      </Box>
    </Card>
  );
}
