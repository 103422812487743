import { styled } from '@f8n-frontend/stitches';

import Text from 'components/base/Text';

const ArtworkCardMetaLabel = styled(Text, {
  whiteSpace: 'nowrap',
  variants: {
    color: {
      light: {
        color: '$black60',
      },
      dark: {
        color: '$white60',
      },
      black: {
        color: '$black100',
      },
    },
  },
});

ArtworkCardMetaLabel.defaultProps = {
  size: 1,
  weight: 'semibold',
};

export const ArtworkCardMetaValue = styled(ArtworkCardMetaLabel, {
  whiteSpace: 'nowrap',
});

ArtworkCardMetaValue.defaultProps = {
  size: 3,
  weight: 'semibold',
};

export default ArtworkCardMetaLabel;
