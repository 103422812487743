import { onGridPx } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';

import { ArtworkCardProps } from '../types';

import { ArtworkCardMediaOverlayContainer } from './ArtworkCardMediaOverlay';

type ContainerProps = ComponentProps<typeof ArtworkCardContainer>;

type ArtworkCardRootProps = Omit<ContainerProps, 'className' | 'variant'> & {
  children: React.ReactNode;
  itemCount: ArtworkCardProps['itemCount'];
};

export default function ArtworkCardRoot(props: ArtworkCardRootProps) {
  const { children, itemCount = 1, ...containerProps } = props;

  return (
    <ArtworkCardRootContainer variant={itemCount > 1 ? 'stack' : 'nft'}>
      <ArtworkCardContainer className="artwork-card" {...containerProps}>
        {children}
      </ArtworkCardContainer>
      {itemCount > 1 && <StackIndicator />}
      {itemCount > 2 && <StackIndicator />}
    </ArtworkCardRootContainer>
  );
}

const StackIndicator = styled('div', {
  position: 'absolute',
  bottom: 0,
  left: 16,
  width: 'calc(100% - 32px)',
  margin: '0 auto',
  background: '$white100',
  boxShadow: '$regular0',
  borderRadius: '$2',
  height: '100%',
});

const ArtworkCardRootContainer = styled('div', {
  // To enable enlarged Home Tab items
  height: '100%',
  // For stack indicators
  position: 'relative',
  // create new stacking context
  isolation: 'isolate',
  variants: {
    variant: {
      nft: {},
      stack: {
        '& > *': {
          transition: 'transform $1 $ease',
          transformOrigin: 'top',
          willChange: 'transform',
        },

        '& > *:nth-child(1)': {
          zIndex: 3,
        },

        [`& > ${StackIndicator}:nth-child(2)`]: {
          borderRadius: 7,
          left: onGridPx(4),
          width: `calc(100% - ${onGridPx(8)})`,
          zIndex: 2,
          transform: 'translateY(5px)',
        },

        [`& > ${StackIndicator}:nth-child(3)`]: {
          borderRadius: 6,
          left: onGridPx(8),
          width: `calc(100% - ${onGridPx(16)})`,
          zIndex: 1,
          transform: 'translateY(10px)',
        },

        '@hover': {
          '&:hover': {
            [`& > *:nth-child(1)`]: {
              transform: 'translateY(-2px)',
            },

            [`& > ${StackIndicator}:nth-child(2)`]: {
              transform: 'translateY(5px)',
            },

            [`& > ${StackIndicator}:nth-child(2):last-child`]: {
              transform: 'translateY(7px)',
            },

            [`& > ${StackIndicator}:nth-child(3)`]: {
              transform: 'translateY(12px)',
            },
          },
        },
      },
    },
  },
});

const hoverCss = {
  boxShadow: '$regular1',
  transform: 'translateY(-2px)',

  [`${ArtworkCardMediaOverlayContainer}`]: {
    opacity: 1,
  },
};

// TODO: update hover states to match figma
const ArtworkCardContainer = styled('div', {
  height: '100%',

  backgroundColor: '$white100',
  display: 'flex',
  flex: 'auto',
  flexDirection: 'column',
  borderRadius: '$2',
  overflow: 'hidden',
  boxShadow: '$regular0',

  transition: 'box-shadow $1 $ease, transform $1 $ease',
  textDecoration: 'none',
  color: '$black100',
  position: 'relative',
  cursor: 'pointer',
  transform: 'translateZ(0)',

  '@bp0': {
    minWidth: 340,
  },

  variants: {
    isAuctionMode: {
      true: {
        background: '$black100',
        color: '$white100',
      },
      false: {
        background: '$white100',
        color: '$black100',
      },
    },
    mediaOverlay: {
      // Always show media overlay
      always: hoverCss,
      // Never show media overlay
      never: {
        [`${ArtworkCardMediaOverlayContainer}`]: {
          opacity: 0,
        },
      },
      // Revealed when hovering over the card
      normal: {
        [`${ArtworkCardMediaOverlayContainer}`]: {
          opacity: 0,
        },

        '@hover': {
          '&:hover': hoverCss,
        },
      },
    },
  },

  defaultVariants: {
    mediaOverlay: 'normal',
  },
});
